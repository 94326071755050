<template>
    <div class="main">
        <header-common title="我的课程"></header-common>
        <div class="top-box">
            <div @click="changeType(1)" class="num-box border-left" :class="listQuery.status === 1?'blue':'def'">
               <div class="top-title">未完成</div>
                <div class="top-num">({{numAll.noCount}})</div>
            </div>
            <div @click="changeType(2)" class="num-box" :class="listQuery.status === 2?'blue':'def'">
                <div class="top-title">已完成</div>
                <div class="top-num">({{numAll.okCount}})</div>
            </div>
            <div @click="changeType(0)" class="num-box border-right" :class="listQuery.status === 0?'blue':'def'">
                <div class="top-title">全部课程</div>
                <div class="top-num">({{numAll.allCount}})</div>
            </div>
        </div>
        <div class="table-box">
            <div class="table-info">
                <el-table
                        v-loading="listLoading"
                        :data="list"
                        border
                        fit
                        highlight-current-row
                        style="width: 100%;text-align: center"
                >
                    <el-table-column
                            type="index"
                            label="序号"
                            width="60"
                            :index="indexMethod"
                            align="center"
                            fixed="left"
                    />
                    <el-table-column label="上课时间" align="center">
                        <template slot-scope="{row}">
                            <span>{{row.class_time}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="当前课程" align="center">
                        <template slot-scope="{row}">
                            <div class="table-button" v-if="row.class_name">
                                第{{ row.arrange }}课-{{row.class_name}}
                            </div>
                            <span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="学习状态" align="center">
                        <template slot-scope="{row}">
                            <span v-if="row.study_status === 1 " style="color: #d20617">未完成</span>
                            <span v-else-if="row.study_status === 2 " style="color: #05a727">已完成</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="完成时间" align="center">
                        <template slot-scope="{row}">
                            <span v-if="row.study_status === 2 ">{{row.class_time}}</span>
                            <span v-else>-</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="width: 100vw;height: 100px"></div>
        </div>
        <div class="page-box">
            <el-pagination v-show="total>0" background layout="prev, pager, next" :total="total" :page.sync="listQuery.pageSize" :limit.sync="listQuery.pageSize" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>

<script>
    import {
        getClassList,
        getClassNum
    } from '@/api/app'
    import HeaderCommon from '@/components/Header'
    export default {
        name: 'course',
        // 添加组件
        components: {
            HeaderCommon
        },
        data() {
            return {
                numAll:'',
                list: null,
                total: 0,
                listLoading: false,
                listQuery: {
                    status:1,
                    page: 1, // 页数
                    pageSize: 10 // 每页条数
                },
                postData: {},
            }},
        created() {
            this.getList()
            this.getNum()
        },
        mounted:function(){
        },
        methods: {
            // 获取序号
            indexMethod(index) {
                index = (index + 1) + (this.listQuery.page - 1) * this.listQuery.pageSize
                return index
            },
            // 获取课程数量
            getNum(){
                getClassNum({}).then(res => {
                    this.numAll = res.data
                })
            },
            // 切换类型
            changeType(type){
                this.listQuery.page = 1
                this.listQuery.status = type
                this.getList()
            },
            // 获取列表
            getList(){
                this.listLoading = true
                getClassList(this.listQuery).then(res => {
                    this.listLoading = false
                    this.list = res.data.list
                    this.total = res.data.count
                })
            },
            // 切换分页
            handleCurrentChange(val) {
                this.listQuery.pageNum = val
                this.getList()
                console.log(`当前页: ${val}`)
            },
            // 格式化进度条
            format(percentage) {
                return `${percentage}%`;
            },
        }
    }
</script>

<style scoped>
    .main{
        width: 100vw;
        height: 100vh;
        position: relative;
       /* background-color: #f6c10a;*/
        overflow: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .top-box{
        width: 80vw;
        display: flex;
        justify-content: center;
        margin: 1rem 0;
    }
    .num-box{
        width: 8rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #999999;
        cursor: pointer;
    }
    .border-left{
        border-radius: 5px 0 0 5px;
    }
    .border-right{
        border-radius: 0 5px 5px 0;
    }
    .red{
        background-color: #e0a504;
    }
    .green{
        background-color: #048d31;
    }
    .blue{
        background-color: #2586FF;
        color: #ffffff;
        border: 1px solid #2586FF;
    }
    .def{
        border: 1px solid #2586FF;
        color: #2586FF;
        background-color: #ffffff;
    }
    .top-num{
        font-size: 1.3rem;
    }
    .top-title{
        font-size: 1.2rem;
        padding-right: 5px;
    }
    .table-box{

    }
    .table-info{
        width: 90vw;
        margin: 0 auto;
    }
    .page-box{
        position: fixed;
        bottom: 0;
        margin: 2vw 0;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
